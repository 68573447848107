// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/dictionaries/ru.po' and run '.po' extractor
export const base = {
  'components.atoms.PlaceLink.tooltip.showOnGoogleMaps':
    'Показать на Google Картах',

  'components.molecules.MapTilesLayer.map': 'Карта',
  'components.molecules.MapTilesLayer.satellite': 'Спутник',

  'components.organisms.ExportButton.geojson': '.geojson',
  'components.organisms.ExportButton.kmz': '.kmz',
  'components.organisms.ExportButton.text': 'Экспортировать выбранные объекты:',

  'components.organisms.Filters.subLabel.0': 'нет локаций',
  'components.organisms.Filters.subLabel.1': '{count} локация',
  'components.organisms.Filters.subLabel.2': '{count} локации',
  'components.organisms.Filters.subLabel.3': '{count} локаций',
  'components.organisms.Filters.subLabel.4': 'нет маршрутов',
  'components.organisms.Filters.subLabel.5': '{count} маршрут',
  'components.organisms.Filters.subLabel.6': '{count} маршрута',
  'components.organisms.Filters.subLabel.7': '{count} маршрутов',

  'components.organisms.SidebarFilters.filters': 'Фильтры',
  'components.organisms.SidebarFilters.filters.0': 'ничего не выбрано',
  'components.organisms.SidebarFilters.filters.1': '{to} объект из {from}',
  'components.organisms.SidebarFilters.filters.2': '{to} объекта из {from}',
  'components.organisms.SidebarFilters.filters.3': '{to} объектов из {from}',

  'constants.filters.labels.bridge': 'Мельницы и мосты',
  'constants.filters.labels.cave': 'Пещеры',
  'constants.filters.labels.christian': 'Часовни и монастыри',
  'constants.filters.labels.fortress': 'Форты и крепости',
  'constants.filters.labels.islamic': 'Мечети',
  'constants.filters.labels.lighthouse': 'Маяки',
  'constants.filters.labels.monument': 'Монументы и памятники',
  'constants.filters.labels.nature': 'Озера, парки, природные зоны',
  'constants.filters.labels.old_town': 'Старинные поселения',
  'constants.filters.labels.other': 'Разное',
  'constants.filters.labels.palace': 'Дворцы',
  'constants.filters.labels.routes': 'Маршруты',
  'constants.filters.labels.waterfall': 'Водопады',

  'hooks.useMapState.newPoint.description': 'новая локация',
  'hooks.useMapState.newPoint.name': 'Неизвестное место',
};
